var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rechargeManagement-list" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: false } }),
      _c("div", { staticClass: "rechargeManagement-row" }, [
        _vm._v("当前保证金余额"),
        _c("span", [_vm._v(_vm._s(_vm.totalAmount))]),
        _vm._v("元")
      ]),
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }